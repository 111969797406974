import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import { Button, SxProps, Theme } from '@mui/material';
import { CharacterSoundModel } from 'models/lesson/LessonModel';
import { ReactNode } from 'react';
import { checkLegacyLogic } from './SelectWordsBox';

export const DoSound = (props: { phraseSound: CharacterSoundModel; phrase: string; groupIndex: number; folder: 'jyutping' | 'pinyin'; sx?: SxProps<Theme>; children: ReactNode }) => {
    const playAudio = () => {
        const isLegacyLogic = checkLegacyLogic(props.phraseSound[props.folder]?.[0]?.sound?.split(' ')?.length ?? 0, props.phrase.length);

        let soundFileName: string[] = [];
        if (isLegacyLogic) {
            const phraseCharacterCount = props.phrase.replace(/[（）()]/g, '').length;
            const sounds = props.phraseSound[props.folder]?.[0]?.sound.split(' ');
            soundFileName = sounds.slice(props.groupIndex * phraseCharacterCount, props.groupIndex * phraseCharacterCount + phraseCharacterCount);
        } else {
            soundFileName = props.phraseSound[props.folder][props.groupIndex].sound.split(' ');
        }

        const audio = new Audio();
        audio.src = '/characters/sounds/' + props.folder + '/' + soundFileName?.[0] + '.mp3';
        var count = 0;
        audio.onended = function () {
            count++;
            audio.src = '/characters/sounds/' + props.folder + '/' + soundFileName?.[count] + '.mp3';
            if (soundFileName && count > soundFileName.length - 1) {
                count = 0;
            } else {
                audio.play();
            }
        };
        audio.play();
    };

    // Render nothing, this component only plays sounds
    return (
        <Button
            variant='contained'
            className='hidden-button'
            startIcon={<VolumeUpIcon />}
            sx={props.sx}
            onClick={(e) => {
                playAudio();
            }}>
            {props.children}
        </Button>
    );
};
