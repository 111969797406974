import { Box, Chip, Stack, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { useTranslation } from 'react-i18next';

import CancelIcon from '@mui/icons-material/Cancel';
import FlipIcon from '@mui/icons-material/Flip';
import { SubmitButton } from 'components/General/Buttons/SubmitButton';
import { SlideComponent, SlideComponentHkcsQ3, SlideComponentHkcsQ3CharacterOptions } from 'models/lesson/LessonModel';
import { SetStateAction, useEffect, useState } from 'react';
import useLessonSlideProps from '../../../hooks/useLessonSlideProps';
import { DoSound } from './components/DoSound';
import { OptionsType } from './components/PropsImagePicker';
import { SelectImageBox } from './components/SelectImageBox';
import { SelectWordsBox } from './components/SelectWordsBox';

export type SelectChangeEvent<T = string> = (Event & { target: { value: T; name: string } }) | React.ChangeEvent<HTMLInputElement>;

export const LessonsSlidePropsHkcsQ3 = ({
    item,
    index,
    setCanSave,
    handleCreate,
}: {
    item: SlideComponent;
    index: number;
    setCanSave?: React.Dispatch<SetStateAction<boolean>>;
    handleCreate?: (item: SlideComponent) => void;
}) => {
    const { t } = useTranslation();
    const { slideComponentHandler } = useLessonSlideProps();

    const [tempCharacter, setTempCharacter] = useState<SlideComponentHkcsQ3CharacterOptions>({
        characterList: [],
        character: 0,
        characterSound: { jyutping: [], pinyin: [] },
        phrase: '',
        phraseSound: {
            jyutping: [],
            pinyin: [],
        },
        phraseSoundGroup: {
            jyutping: [{ startIndex: 0 }],
            pinyin: [{ startIndex: 0 }],
        },
        isCorrect: true,
    });

    let itemContent: SlideComponentHkcsQ3 = item.content as SlideComponentHkcsQ3;

    const updateUI = (save: boolean) => {
        if (handleCreate) {
            handleCreate(item);
        } else {
            slideComponentHandler({ ...item }, save);
        }
    };

    const [gridHeight, setGridHeight] = useState('40vh');

    useEffect(() => {
        function handleResize() {
            // Get 50% of the height of the parent element
            // Assuming the parent's height is set to 80vh

            const parentOffset = 550;
            const newHeight = `${window.innerHeight - parentOffset}px`;
            setGridHeight(newHeight);
        }

        // Set the height initially
        handleResize();

        // Add event listener
        window.addEventListener('resize', handleResize);

        // Remove event listener on cleanup
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        setCanSave?.((itemContent.image !== '' || itemContent.imageURL !== '') && itemContent.characterList.length > 0 && itemContent.characterOptions.length > 0);
    }, [item]);

    return (
        <>
            <Stack sx={{ flex: 1, m: 1 }}>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                    <Box sx={{ flex: '1 1 150px' }}>
                        <SelectImageBox
                            keepMargin={true}
                            imageURL={itemContent.imageURL ?? ''}
                            image={itemContent.image ?? ''}
                            flip={itemContent.flip}
                            onSelected={(imageURL: string) => {
                                itemContent.imageURL = imageURL;
                                updateUI(true);
                            }}
                            handleDelete={() => {
                                itemContent.imageURL = '';
                                itemContent.image = '';
                                updateUI(true);
                            }}
                            onClickHandler={(option: OptionsType) => {
                                itemContent.image = option?.value ?? '';
                                updateUI(true);
                            }}
                        />
                        <ToggleButton
                            value='1'
                            selected={itemContent.flip === -1}
                            onChange={() => {
                                if (itemContent.flip === -1) {
                                    itemContent.flip = 1;
                                } else {
                                    itemContent.flip = -1;
                                }
                                updateUI(true);
                            }}
                            aria-label='bold'
                            sx={{
                                width: '100%',
                                mt: 1,
                                borderRadius: '12px',
                                fontWeight: '700',
                                fontSize: '18px',
                                lineHeight: '150%',
                                color: '#FDFCFC',
                                backgroundColor: itemContent.flip === -1 ? '#efeae6' : '#F06E3C',
                                '&:hover': {
                                    backgroundColor: itemContent.flip === -1 ? '#e2dddb' : '#C84522',
                                },
                            }}>
                            <FlipIcon sx={{ mr: 2 }} /> {t('flip')}
                        </ToggleButton>
                    </Box>
                    <Box sx={{ flex: '1 1 150px' }}>
                        <SelectWordsBox
                            textLabel={t('hkcs.q3.selectKeyword')}
                            singleWord={false}
                            canSelectQuestion={true}
                            questionLabel={t('hkcs.q3.questionLabel')}
                            characterList={itemContent?.characterList}
                            phrase={itemContent.phrase}
                            phraseSound={itemContent.phraseSound}
                            phraseSoundGroup={itemContent.phraseSoundGroup}
                            onPhraseSoundGroupChanged={(phraseSoundGroup) => {
                                itemContent.phraseSoundGroup = phraseSoundGroup;
                                updateUI(true);
                            }}
                            selectedCharacterIndex={itemContent.character}
                            onCharacterPicked={(characterPhrase) => {
                                itemContent.characterList = characterPhrase?.character;
                                itemContent.character = 0;
                                itemContent.characterSound = {
                                    jyutping: characterPhrase?.character?.[itemContent.character]?.jyutping,
                                    pinyin: characterPhrase?.character?.[itemContent.character]?.pinyin,
                                };
                                itemContent.phrase = characterPhrase?.phrase;
                                itemContent.phraseSound = {
                                    jyutping: characterPhrase?.jyutping,
                                    pinyin: characterPhrase?.pinyin,
                                };
                                updateUI(true);
                            }}
                            onQuestionCharacterSelected={(index, character) => {
                                itemContent.character = index;

                                itemContent.characterSound = {
                                    jyutping: character.jyutping,
                                    pinyin: character.pinyin,
                                };

                                updateUI(true);
                            }}
                        />
                        {itemContent?.phrase && (
                            <Stack direction={'row'} spacing={1} sx={{ mt: 1 }}>
                                <DoSound
                                    phraseSound={itemContent?.phraseSound}
                                    phrase={itemContent?.phrase}
                                    groupIndex={itemContent?.phraseSoundGroup?.jyutping?.[0]?.startIndex || 0}
                                    folder='jyutping'
                                    sx={{
                                        bgcolor: '#23B1D3',
                                        color: '#FAFAFA',
                                        '&:hover': {
                                            bgcolor: '#23B1D3',
                                            color: '#FAFAFA',
                                            opacity: '0.7',
                                        },
                                        pointerEvents: 'auto',
                                    }}>
                                    {t('cantonese')}
                                </DoSound>

                                <DoSound
                                    phraseSound={itemContent?.phraseSound}
                                    phrase={itemContent?.phrase}
                                    groupIndex={itemContent?.phraseSoundGroup?.pinyin?.[0]?.startIndex || 0}
                                    folder='pinyin'
                                    sx={{
                                        bgcolor: '#FFFFFF',
                                        color: '#29C0E7',
                                        '&:hover': {
                                            bgcolor: '#FFFFFF',
                                            color: '#29C0E7',
                                            opacity: '0.7',
                                        },
                                        pointerEvents: 'auto',
                                        borderWidth: 1,
                                        borderStyle: 'solid',
                                        borderColor: '#29C0E7',
                                    }}>
                                    {t('putonghua')}
                                </DoSound>
                            </Stack>
                        )}
                    </Box>
                    <Box sx={{ flex: '1 1 150px' }}>
                        <SelectWordsBox
                            enabled={itemContent.characterOptions.length < 5}
                            textLabel={t('hkcs.q3.selectRelatedCharacter')}
                            singleWord={true}
                            canSelectQuestion={false}
                            questionLabel={t('hkcs.q3.questionLabel')}
                            characterList={tempCharacter?.characterList}
                            phrase={tempCharacter.phrase}
                            phraseSound={tempCharacter.phraseSound}
                            phraseSoundGroup={tempCharacter.phraseSoundGroup}
                            onPhraseSoundGroupChanged={(phraseSoundGroup) => {
                                setTempCharacter((prev) => ({ ...prev, phraseSoundGroup }));
                                updateUI(true);
                            }}
                            selectedCharacterIndex={tempCharacter.character}
                            onCharacterPicked={(characterPhrase) => {
                                setTempCharacter((prev) => ({
                                    ...prev,
                                    characterList: characterPhrase?.character,
                                    character: 0,
                                    characterSound: {
                                        jyutping: characterPhrase?.character?.[prev.character]?.jyutping,
                                        pinyin: characterPhrase?.character?.[prev.character]?.pinyin,
                                    },
                                    phrase: characterPhrase?.phrase,
                                    phraseSound: {
                                        jyutping: characterPhrase?.jyutping,
                                        pinyin: characterPhrase?.pinyin,
                                    },
                                }));
                                updateUI(true);
                            }}
                            onQuestionCharacterSelected={(index, character) => {
                                setTempCharacter((prev) => ({
                                    ...prev,
                                    character: index,
                                    characterSound: {
                                        jyutping: character.jyutping,
                                        pinyin: character.pinyin,
                                    },
                                }));
                                updateUI(true);
                            }}
                        />

                        {tempCharacter.characterList.length > 0 && (
                            <ToggleButtonGroup
                                value={tempCharacter.isCorrect ? 'true' : 'false'}
                                exclusive
                                onChange={(e, newAlignment) => {
                                    setTempCharacter((prev) => {
                                        if (!newAlignment) {
                                            return prev;
                                        }

                                        return {
                                            ...prev,
                                            isCorrect: newAlignment === 'true',
                                        };
                                    });
                                }}
                                fullWidth
                                sx={{ mt: 1 }}>
                                <ToggleButton value='true'>{t('hkcs.q3.correct')}</ToggleButton>
                                <ToggleButton value='false'>{t('hkcs.q3.incorrect')}</ToggleButton>
                            </ToggleButtonGroup>
                        )}

                        {tempCharacter?.phrase && (
                            <Stack direction={'row'} spacing={1} sx={{ mt: 1 }}>
                                <DoSound
                                    phraseSound={tempCharacter?.phraseSound}
                                    phrase={tempCharacter?.phrase}
                                    groupIndex={tempCharacter?.phraseSoundGroup?.jyutping?.[0]?.startIndex || 0}
                                    folder='jyutping'
                                    sx={{
                                        bgcolor: '#23B1D3',
                                        color: '#FAFAFA',
                                        '&:hover': {
                                            bgcolor: '#23B1D3',
                                            color: '#FAFAFA',
                                            opacity: '0.7',
                                        },
                                        pointerEvents: 'auto',
                                    }}>
                                    {t('cantonese')}
                                </DoSound>

                                <DoSound
                                    phraseSound={tempCharacter?.phraseSound}
                                    phrase={tempCharacter?.phrase}
                                    groupIndex={tempCharacter?.phraseSoundGroup?.pinyin?.[0]?.startIndex || 0}
                                    folder='pinyin'
                                    sx={{
                                        bgcolor: '#FFFFFF',
                                        color: '#29C0E7',
                                        '&:hover': {
                                            bgcolor: '#FFFFFF',
                                            color: '#29C0E7',
                                            opacity: '0.7',
                                        },
                                        pointerEvents: 'auto',
                                        borderWidth: 1,
                                        borderStyle: 'solid',
                                        borderColor: '#29C0E7',
                                    }}>
                                    {t('putonghua')}
                                </DoSound>
                            </Stack>
                        )}

                        {tempCharacter.characterList.length > 0 && (
                            <SubmitButton
                                onClick={() => {
                                    itemContent.characterOptions = [...itemContent.characterOptions, tempCharacter];

                                    // reset the temp character
                                    setTempCharacter({
                                        characterList: [],
                                        character: 0,
                                        characterSound: { jyutping: [], pinyin: [] },
                                        phrase: '',
                                        phraseSound: {
                                            jyutping: [],
                                            pinyin: [],
                                        },
                                        phraseSoundGroup: {
                                            jyutping: [{ startIndex: 0 }],
                                            pinyin: [{ startIndex: 0 }],
                                        },
                                        isCorrect: true,
                                    });

                                    updateUI(true);
                                }}
                                autoFocus
                                fullWidth
                                sx={{ mt: 1 }}>
                                {t('hkcs.q3.addCharacter')}
                            </SubmitButton>
                        )}
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                                flexWrap: 'wrap',
                                listStyle: 'none',
                                p: 1,
                                m: 0,
                            }}
                            component='ul'>
                            {itemContent.characterOptions.map((data, index) => {
                                return (
                                    <Chip
                                        key={index}
                                        label={data.characterList[data.character].character}
                                        onDelete={() => {
                                            itemContent.characterOptions = itemContent.characterOptions.filter((_, i) => i !== index);
                                            updateUI(true);
                                        }}
                                        deleteIcon={<CancelIcon sx={{ color: '#FFFFFF' }} />} // Change the color here
                                        sx={{ m: 0.3, background: data.isCorrect ? '#2E7D32' : '#D32F2F', color: '#fff' }}
                                    />
                                );
                            })}
                        </Box>
                    </Box>
                </Box>
            </Stack>
        </>
    );
};
