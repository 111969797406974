import { gql, useQuery } from '@apollo/client';
import { ERROR_TYPE, handleException } from 'models/ErrorHandling';
import {
    CharacterSoundModel,
    SlideComponent,
    SlideComponentAudio,
    SlideComponentBackground,
    SlideComponentCharacter,
    SlideComponentFillInTheBlank,
    SlideComponentH5P,
    SlideComponentHkcsEnQ1,
    SlideComponentHkcsEnQ2,
    SlideComponentHkcsQ1,
    SlideComponentHkcsQ2,
    SlideComponentHkcsQ3,
    SlideComponentImage,
    SlideComponentMatching,
    SlideComponentMultipleChoice,
    SlideComponentPunctuationMark,
    SlideComponentReadingComprehension,
    SlideComponentReorderSentenceExercise,
    SlideComponentSasAudio,
    SlideComponentSasFillInTheBlanks,
    SlideComponentSasFillInTheBlanksWriting,
    SlideComponentSasRadicalMatching,
    SlideComponentText,
    SlideModel,
} from 'models/lesson/LessonModel';
import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { UserAuthContext } from '../../../contexts/UserAuthContext';
import { SlideListContext } from '../contexts/LessonsSlideContext';

import { enqueueSnackbar } from 'notistack';
import useLessonRefresh from './common/useLessonRefresh';
import useLessonSlideMutation from './useLessonSlideMutation';
const { v4: uuidv4 } = require('uuid');

const reorder = (list: SlideModel[], startIndex: number, endIndex: number): SlideModel[] => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result.map((e, i) => {
        return { ...e, lastSort: e.sort, sort: i };
    });
};

const SLIDE_QUERY = gql`
    query GetRows($slideID: ID!) {
        slide(where: { id: $slideID }) {
            id
            sort
            data
        }
    }
`;

export const baseWidth = parseInt(process.env.REACT_APP_DESIGN_RESOLUTION_WIDTH ?? '0') / 2;
export const baseHeight = parseInt(process.env.REACT_APP_DESIGN_RESOLUTION_HEIGHT ?? '0') / 2;
export const ratio = parseInt(process.env.REACT_APP_DESIGN_RESOLUTION_WIDTH ?? '0') / parseInt(process.env.REACT_APP_DESIGN_RESOLUTION_HEIGHT ?? '0');

let saveToDB = false;

export default function useLessonSlide() {
    const { accessDenied } = useContext(UserAuthContext);
    const { t } = useTranslation();
    const { setLoading, lessonData, dispatchLessonData, selectedSlideIndex, setSelectedSlideIndex, slideRoot, setSlideRoot } = useContext(SlideListContext);
    const { refreshLesson } = useLessonRefresh();
    const { doUpdateSorting, addSlideMutation, updateOneSlide } = useLessonSlideMutation();
    const { refetch: querySlide } = useQuery(SLIDE_QUERY, {
        variables: {
            slideID: '',
        },
        fetchPolicy: 'no-cache',
        notifyOnNetworkStatusChange: true,
    });

    const reorderItem = (result: any) => {
        if (!result.destination) {
            return;
        }
        const newItems = reorder(lessonData?.latestLessonDraft?.slides ?? [], result.source.index, result.destination.index);

        const lastSlideID = lessonData?.latestLessonDraft?.slides?.[selectedSlideIndex].id;
        dispatchLessonData({
            type: 'SetSlides',
            payload: { slideIndex: selectedSlideIndex, data: newItems },
        });
        updateIndexFromSlideID(lastSlideID, newItems);
        updateSorting(newItems);
    };

    const updateIndexFromSlideID = (slideID: string, itemSource: SlideModel[]) => {
        itemSource.map((item, index) => {
            if (item.id === slideID) {
                setSelectedSlideIndex(index);
            }
        });
    };

    const updateSorting = (newItems: SlideModel[]) => {
        let filterItems = newItems.filter((item) => item.lastSort !== undefined && item.sort !== item.lastSort);
        let updateItems = filterItems.map((item) => {
            return { data: { sort: item.sort }, where: { id: item.id } };
        });

        doUpdateSorting({ variables: { data: updateItems } });
    };

    const addSlide = (lessonID: string, lessonVersionID: string) =>
        new Promise(async (resolve, reject) => {
            try {
                await addSlideMutation({
                    variables: {
                        lessonVersionID: lessonVersionID ?? '',
                    },
                });

                refreshLesson(lessonID);
                resolve('');
            } catch (err) {
                console.log(err);
                reject(err);
            }
        });

    const refreshSlide = async (slideID: string) => {
        setLoading(true);

        try {
            let res = await querySlide({
                slideID: slideID,
            });

            // insert new slide components
            dispatchLessonData({
                type: 'SetSlideComponent',
                payload: {
                    slideIndex: selectedSlideIndex,
                    data: res.data.slide.data ? JSON.parse(res.data.slide.data) : [],
                },
            });
        } catch (err) {
            const errorMsg = handleException(err);
            if (errorMsg === ERROR_TYPE.KS_ACCESS_DENIED) {
                accessDenied(errorMsg);
            } else {
                enqueueSnackbar(errorMsg, {
                    variant: 'error',
                    anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
                });
            }
        } finally {
            setLoading(false);
        }
    };

    const newAudioProps: SlideComponent = {
        id: uuidv4(),
        type: 'audio',
        pos: { x: 0, y: 0 },
        size: { width: 100, height: 100 },
        content: { audio: [] } as SlideComponentAudio,
    };

    const newCharacterProps: SlideComponent = {
        id: uuidv4(),
        type: 'character',
        pos: { x: 0, y: 0 },
        size: { width: 0, height: 0 },
        content: {
            image: '',
            characterList: [],
            character: 0,
            characterSound: {} as CharacterSoundModel,
            phrase: '',
            phraseSound: {} as CharacterSoundModel,
            phraseSoundGroup: {
                jyutping: [{ startIndex: 0 }],
                pinyin: [{ startIndex: 0 }],
            },
            imageURL: '',
            gameType: 'OneCard',
            flip: 1,
            zIndex: 1,
        } as SlideComponentCharacter,
    };

    const newImageProps: SlideComponent = {
        id: uuidv4(),
        type: 'image',
        pos: { x: 0, y: 0 },
        size: { width: 0, height: 0 },
        content: { filename: '', flip: 1, zIndex: 1 } as SlideComponentImage,
    };

    const newBackgroundProps: SlideComponent = {
        id: uuidv4(),
        type: 'background',
        pos: { x: 0, y: 0 },
        size: {
            width: 0,
            height: 0,
        },
        content: { filename: '', imageURL: '', flip: 1, zIndex: 0 } as SlideComponentBackground,
    };

    const newTextProps: SlideComponent = {
        id: uuidv4(),
        type: 'text',
        pos: { x: 0, y: 0 },
        size: { width: 300, height: 60 },
        content: { text: '', jyutping: [], fontSize: 24, zIndex: 1 } as SlideComponentText,
    };

    const newReorderSentenceProps: SlideComponent = {
        id: uuidv4(),
        type: 'reorderSentence',
        pos: { x: 0, y: 0 },
        size: { width: 300, height: 60 },
        content: {
            grade: 1,
            questionCount: 1,
            questionLength: 'short',
            questionTopic: '',
            questions: [],
            zIndex: 2,
        } as SlideComponentReorderSentenceExercise,
    };

    const newMatchingProps: SlideComponent = {
        id: uuidv4(),
        type: 'matching',
        pos: { x: 0, y: 0 },
        size: { width: 800, height: 600 },
        content: {
            questionCount: 2,
            questions: [
                {
                    id: uuidv4(),
                    image: '',
                    imageURL: '',
                    flip: 1,
                    answer: '',
                    sort: 0,
                },
                {
                    id: uuidv4(),
                    image: '',
                    imageURL: '',
                    flip: 1,
                    answer: '',
                    sort: 0,
                },
            ],
            zIndex: 2,
        } as SlideComponentMatching,
    };

    const newFillInTheBlankProps: SlideComponent = {
        id: uuidv4(),
        type: 'fillInTheBlank',
        pos: { x: 0, y: 0 },
        size: { width: 800, height: 600 },
        content: {
            questionTopic: '',
            questionLength: 'short',
            questions: [],
            zIndex: 2,
        } as SlideComponentFillInTheBlank,
    };

    const newMultipleChoiceProps: SlideComponent = {
        id: uuidv4(),
        type: 'multipleChoice',
        pos: { x: 0, y: 0 },
        size: { width: 300, height: 60 },
        content: {
            questionTopic: '',
            questionLength: 'short',
            questions: [],
            zIndex: 2,
        } as SlideComponentMultipleChoice,
    };

    const newPunctuationMark: SlideComponent = {
        id: uuidv4(),
        type: 'punctuationMark',
        pos: { x: 0, y: 0 },
        size: { width: 300, height: 60 },
        content: {
            questionCount: 1,
            questionLength: 'short',
            questionTopic: '',
            questions: [],
            zIndex: 2,
        } as SlideComponentPunctuationMark,
    };

    const newReadingComprehension: SlideComponent = {
        id: uuidv4(),
        type: 'readingComprehension',
        pos: { x: 0, y: 0 },
        size: { width: 300, height: 60 },
        content: {
            questionCount: 1,
            articleTopic: '',
            questions: [],
            articleText: '',
            prompt_fillInTheBlank: '',
            prompt_multipleChoice: '',
            itemContent_fillInTheBlank: newFillInTheBlankProps.content as SlideComponentFillInTheBlank,
            itemContent_multipleChoice: newMultipleChoiceProps.content as SlideComponentMultipleChoice,
            zIndex: 2,
        } as SlideComponentReadingComprehension,
    };

    const newH5PProps: SlideComponent = {
        id: uuidv4(),
        type: 'h5p',
        pos: { x: 0, y: 0 },
        size: {
            width: baseWidth || 300,
            height: baseHeight || 100,
        },
        content: {
            h5pPath: '',
            h5pContentJsonPath: '',
            zIndex: 0,
            questions: [],
            thumbnail: '',
        } as SlideComponentH5P,
    };

    const newHkcsQ1Props: SlideComponent = {
        id: uuidv4(),
        type: 'hkcsQ1',
        pos: { x: 0, y: 0 },
        size: { width: 0, height: 0 },
        content: {
            characterList: [],
            character: 0,
            characterSound: {} as CharacterSoundModel,
            phrase: '',
            phraseSound: {} as CharacterSoundModel,
            phraseSoundGroup: {
                jyutping: [{ startIndex: 0 }],
                pinyin: [{ startIndex: 0 }],
            },
            image1: '',
            image1URL: '',
            keyword1: '',
            image2: '',
            image2URL: '',
            keyword2: '',
            questionCount: 4,
            questions: [
                {
                    id: uuidv4(),
                    image: '',
                    imageURL: '',
                    flip: 1,
                    sort: 0,
                },
                {
                    id: uuidv4(),
                    image: '',
                    imageURL: '',
                    flip: 1,
                    sort: 0,
                },
                {
                    id: uuidv4(),
                    image: '',
                    imageURL: '',
                    flip: 1,
                    sort: 0,
                },
                {
                    id: uuidv4(),
                    image: '',
                    imageURL: '',
                    flip: 1,
                    sort: 0,
                },
            ],
            zIndex: 2,
        } as SlideComponentHkcsQ1,
    };

    const newHkcsQ2Props: SlideComponent = {
        id: uuidv4(),
        type: 'hkcsQ2',
        pos: { x: 0, y: 0 },
        size: { width: 0, height: 0 },
        content: {
            image: '',
            characterList: [],
            character: 0,
            characterSound: {} as CharacterSoundModel,
            phrase: '',
            phraseSound: {} as CharacterSoundModel,
            phraseSoundGroup: {
                jyutping: [{ startIndex: 0 }],
                pinyin: [{ startIndex: 0 }],
            },
            imageURL: '',
            flip: 1,
            zIndex: 2,
        } as SlideComponentHkcsQ2,
    };

    const newHkcsQ3Props: SlideComponent = {
        id: uuidv4(),
        type: 'hkcsQ3',
        pos: { x: 0, y: 0 },
        size: { width: 0, height: 0 },
        content: {
            image: '',
            characterList: [],
            character: 0,
            characterSound: {} as CharacterSoundModel,
            phrase: '',
            phraseSound: {} as CharacterSoundModel,
            phraseSoundGroup: {
                jyutping: [{ startIndex: 0 }],
                pinyin: [{ startIndex: 0 }],
            },
            imageURL: '',
            flip: 1,
            zIndex: 2,
            characterOptions: [],
        } as SlideComponentHkcsQ3,
    };

    const newHkcsEnQ1Props: SlideComponent = {
        id: uuidv4(),
        type: 'hkcsEnQ1',
        pos: { x: 0, y: 0 },
        size: { width: 0, height: 0 },
        content: {
            image: '',
            imageURL: '',
            flip: 1,
            zIndex: 2,
            phrase: '',
            audio: [],
        } as SlideComponentHkcsEnQ1,
    };

    const newHkcsEnQ2Props: SlideComponent = {
        id: uuidv4(),
        type: 'hkcsEnQ2',
        pos: { x: 0, y: 0 },
        size: { width: 0, height: 0 },
        content: {
            image: '',
            imageURL: '',
            flip: 1,
            zIndex: 2,
            phrase: '',
            sampleSentence: '',
            audio: [],
        } as SlideComponentHkcsEnQ2,
    };

    const newSasAudioProps: SlideComponent = {
        id: uuidv4(),
        type: 'sasAudio',
        pos: { x: 0, y: 0 },
        size: { width: 0, height: 0 },
        content: {
            exampleImage: '',
            exampleImageURL: '',
            exampleText1: '',
            exampleText2: '',
            exampleImageFlip: 1,
            questionImage: '',
            questionImageURL: '',
            questionText: '',
            questionImageFlip: 1,
            zIndex: 2,
        } as SlideComponentSasAudio,
    };

    const newSasFillInTheBlanksProps: SlideComponent = {
        id: uuidv4(),
        type: 'sasFillInTheBlanks',
        pos: { x: 0, y: 0 },
        size: { width: 0, height: 0 },
        content: {
            image: '',
            imageURL: '',
            flip: 1,
            instructionText: t('hksas.fillInTheBlanks.defaultInstruction'),
            questions: [{ id: uuidv4(), questionText: '', sort: 0 }],
            answers: [],
            extraAnswers: [],
            zIndex: 2,
        } as SlideComponentSasFillInTheBlanks,
    };

    const newSasFillInTheBlanksWritingProps: SlideComponent = {
        id: uuidv4(),
        type: 'sasFillInTheBlanksWriting',
        pos: { x: 0, y: 0 },
        size: { width: 0, height: 0 },
        content: {
            image: '',
            imageURL: '',
            flip: 1,
            locale: 'zh-CN',
            instructionText: t('hksas.fillInTheBlanksWriting.defaultInstruction'),
            questions: [{ id: uuidv4(), questionText: '', sort: 0 }],
            answers: [],
            extraAnswers: [],
            zIndex: 2,
        } as SlideComponentSasFillInTheBlanksWriting,
    };

    const newSasRadicalMatchingProps: SlideComponent = {
        id: uuidv4(),
        type: 'sasRadicalMatching',
        pos: { x: 0, y: 0 },
        size: { width: 0, height: 0 },
        content: {
            layout: 1,
            answerPosition: 1,
            characterList: [],
            character: 0,
            characterSound: {} as CharacterSoundModel,
            phrase: '',
            phraseSound: {} as CharacterSoundModel,
            phraseSoundGroup: {
                jyutping: [{ startIndex: 0 }],
                pinyin: [{ startIndex: 0 }],
            },
            image: '',
            imageURL: '',
            flip: 1,
            zIndex: 2,
            questionImageURL1: '',
            questionImageURL2: '',
            answers: [
                { correctAnswerPosition: 0, text: '', urlPath: '' },
                { correctAnswerPosition: 0, text: '', urlPath: '' },
                { correctAnswerPosition: 0, text: '', urlPath: '' },
                { correctAnswerPosition: 0, text: '', urlPath: '' },
            ],
        } as SlideComponentSasRadicalMatching,
    };

    const createComponent = (objectComponent: SlideComponent) => {
        saveToDB = true;
        dispatchLessonData({
            type: 'AddSlideComponent',
            payload: { slideIndex: selectedSlideIndex, data: objectComponent },
        });
    };

    const updateSlide = async () => {
        if (lessonData?.latestLessonDraft?.slides?.[selectedSlideIndex].id) {
            try {
                // const thumbnailFile = await saveSlideThumbnail();
                await updateOneSlide({
                    variables: {
                        data: JSON.stringify(lessonData.latestLessonDraft?.slides[selectedSlideIndex].data),
                        // file: thumbnailFile,
                        slideID: lessonData?.latestLessonDraft?.slides?.[selectedSlideIndex].id,
                    },
                });
            } catch (err) {
                const errorMsg = handleException(err);
                if (errorMsg === ERROR_TYPE.KS_ACCESS_DENIED) {
                    accessDenied(errorMsg);
                } else {
                    // alert(errorMsg);
                }
            }
        }
    };

    const updateItemProperties = async (item: SlideComponent, save: boolean) => {
        if (save) {
            saveToDB = save;
        }
        dispatchLessonData({
            type: 'UpdateSlideComponent',
            payload: { slideIndex: selectedSlideIndex, data: item },
        });
    };

    useEffect(() => {
        if (lessonData?.latestLessonDraft?.slides?.length > selectedSlideIndex && lessonData?.latestLessonDraft?.slides?.[selectedSlideIndex].data) {
            if (saveToDB) {
                console.log('saved?');
                updateSlide();
                saveToDB = false;
            }
        }
    }, [lessonData?.latestLessonDraft?.slides?.[selectedSlideIndex]?.data]);

    const deleteSlideItem = (index: number) => {
        saveToDB = true;
        dispatchLessonData({
            type: 'DeleteSlideComponent',
            payload: { slideIndex: selectedSlideIndex, componentIndex: index },
        });
    };

    // const saveSlideThumbnail = () =>
    //   new Promise<File>((resolve, reject) => {
    //     if (slideRoot && slideRoot.current) {
    //       const domtoimage = require('dom-to-image');
    //       domtoimage.toBlob(slideRoot?.current).then(function (blob: Blob) {
    //         const thumbnailFile = new File([blob], 'thumbnail.jpg', {
    //           type: 'image/jpeg',
    //         });

    //         // update current slide image
    //         setSlideData((prev) => {
    //           return [
    //             ...prev.map((item: SlideModel, index: number) => {
    //               if (item.id === slideData[selectedSlideIndex].id) {
    //                 return {
    //                   ...item,
    //                   thumbnail: { filename: URL.createObjectURL(blob) },
    //                 };
    //               }
    //               return { ...item };
    //             }),
    //           ];
    //         });

    //         resolve(thumbnailFile);
    //       });
    //     }
    //   });

    return {
        reorderItem,
        addSlide,
        refreshSlide,
        ratio,
        newAudioProps,
        newCharacterProps,
        newImageProps,
        newBackgroundProps,
        newTextProps,
        newReorderSentenceProps,
        newMatchingProps,
        newFillInTheBlankProps,
        newMultipleChoiceProps,
        newPunctuationMark,
        newReadingComprehension,
        newH5PProps,
        newHkcsQ1Props,
        newHkcsQ2Props,
        newHkcsQ3Props,
        newHkcsEnQ1Props,
        newHkcsEnQ2Props,
        newSasAudioProps,
        newSasFillInTheBlanksProps,
        newSasFillInTheBlanksWritingProps,
        newSasRadicalMatchingProps,
        createComponent,
        updateItemProperties,
        deleteSlideItem,
        setSlideRoot,
    };
}
