import _ from 'lodash';
import { Stack, Grid, ToggleButton, TextField, Typography, IconButton, Button, Box, FormControlLabel, RadioGroup, FormControl, Radio } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { SlideComponent, SlideComponentSasFillInTheBlanksWritingQuestionModels, SlideComponentSasFillInTheBlanksWriting } from 'models/lesson/LessonModel';
import useLessonSlideProps from '../../../hooks/useLessonSlideProps';
import { SelectImageBox } from './components/SelectImageBox';
import { OptionsType } from './components/PropsImagePicker';
import { SetStateAction, useCallback, useEffect, useState } from 'react';
import FlipIcon from '@mui/icons-material/Flip';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

import { createDebouncedSaveHandler } from './functions/debouncedSaveHandler';
const debouncedSaveHandler = createDebouncedSaveHandler();

const { v4: uuidv4 } = require('uuid');
const questionLimit: number = 999;

export type SelectChangeEvent<T = string> = (Event & { target: { value: T; name: string } }) | React.ChangeEvent<HTMLInputElement>;

export const LessonsSlidePropsSasFillInTheBlanksWriting = ({
    item,
    index,
    setCanSave,
    handleCreate,
}: {
    item: SlideComponent;
    index: number;
    setCanSave?: React.Dispatch<SetStateAction<boolean>>;
    handleCreate?: (item: SlideComponent) => void;
}) => {
    const { t } = useTranslation();
    const { slideComponentHandler } = useLessonSlideProps();

    let itemContent: SlideComponentSasFillInTheBlanksWriting = item.content as SlideComponentSasFillInTheBlanksWriting;

    const updateUI = (save: boolean) => {
        if (handleCreate) {
            handleCreate(item);
        } else {
            slideComponentHandler({ ...item }, save);
        }
    };

    const addQuestion = () => {
        itemContent.questions.push({
            id: uuidv4(),
            questionText: '',
            sort: 0,
        });
    };
    const handleRemoveQuestion = (index: number) => {
        // Handle the remove action here
        itemContent.questions.splice(index, 1);
    };

    const onQuestionChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {};

    const [gridHeight, setGridHeight] = useState('40vh');

    useEffect(() => {
        function handleResize() {
            // Get 50% of the height of the parent element
            // Assuming the parent's height is set to 80vh

            const parentOffset = 550;
            const newHeight = `${window.innerHeight - parentOffset}px`;
            setGridHeight(newHeight);
        }

        // Set the height initially
        handleResize();

        // Add event listener
        window.addEventListener('resize', handleResize);

        // Remove event listener on cleanup
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        setCanSave?.(
            (itemContent.image !== '' || itemContent.imageURL !== '') && itemContent.instructionText !== '' && itemContent.questions.length > 0 && !hasEmptyFieldInArray(itemContent.questions),
        );
    }, [item]);

    const hasEmptyFieldInArray = (questions: SlideComponentSasFillInTheBlanksWritingQuestionModels[]) => {
        if (questions.length <= 0) {
            return true;
        }
        for (const question of questions) {
            if (question.questionText.trim() === '') {
                return true;
            }
        }
    };

    return (
        <>
            <Stack sx={{ flex: 1, m: 1 }}>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                    <Box sx={{ flex: '1 1 150px' }}>
                        <Stack spacing={1}>
                            <SelectImageBox
                                keepMargin={true}
                                imageURL={itemContent.imageURL ?? ''}
                                image={itemContent.image ?? ''}
                                flip={itemContent.flip}
                                onSelected={(imageURL: string) => {
                                    itemContent.imageURL = imageURL;
                                    updateUI(true);
                                }}
                                handleDelete={() => {
                                    itemContent.imageURL = '';
                                    itemContent.image = '';
                                    updateUI(true);
                                }}
                                onClickHandler={(option: OptionsType) => {
                                    itemContent.image = option?.value ?? '';
                                    updateUI(true);
                                }}
                            />
                            <ToggleButton
                                value='1'
                                selected={itemContent.flip === -1}
                                onChange={() => {
                                    if (itemContent.flip === -1) {
                                        itemContent.flip = 1;
                                    } else {
                                        itemContent.flip = -1;
                                    }
                                    updateUI(true);
                                }}
                                aria-label='bold'
                                sx={{
                                    width: '100%',
                                    mt: 1,
                                    borderRadius: '12px',
                                    fontWeight: '700',
                                    fontSize: '18px',
                                    lineHeight: '150%',
                                    color: '#FDFCFC',
                                    backgroundColor: itemContent.flip === -1 ? '#efeae6' : '#F06E3C',
                                    '&:hover': {
                                        backgroundColor: itemContent.flip === -1 ? '#e2dddb' : '#C84522',
                                    },
                                }}>
                                <FlipIcon sx={{ mr: 2 }} /> {t('flip')}
                            </ToggleButton>
                        </Stack>
                    </Box>
                    <Box sx={{ flex: '1 1 350px' }}>
                        <Stack spacing={1}>
                            <Typography>{t('hksas.fillInTheBlanks.questionLocale')}</Typography>
                            <FormControl component='fieldset'>
                                <RadioGroup
                                    row
                                    aria-label='options'
                                    value={itemContent.locale ?? 'zh-CN'}
                                    onChange={(e) => {
                                        itemContent.locale = e.target.value;
                                        updateUI(false);
                                        debouncedSaveHandler(() => {
                                            updateUI(true);
                                        });
                                    }}>
                                    <FormControlLabel value='zh-HK' control={<Radio />} label={t('lesson.zh-HK')} />
                                    <FormControlLabel value='zh-CN' control={<Radio />} label={t('lesson.zh-CN')} />
                                </RadioGroup>
                            </FormControl>

                            <Typography>{t('hksas.fillInTheBlanksWriting.instruction')}</Typography>
                            <TextField
                                label={t('hksas.fillInTheBlanksWriting.inputInstruction')}
                                type='text'
                                sx={{ width: '100%' }}
                                value={itemContent.instructionText}
                                onChange={(e) => {
                                    itemContent.instructionText = e.target.value;
                                    updateUI(false);
                                    debouncedSaveHandler(() => {
                                        updateUI(true);
                                    });
                                }}
                                // onBlur={(e) => {
                                //   updateUI(true);
                                // }}
                            />
                            <Stack direction='row' justifyContent='space-between' alignItems='flex-end'>
                                <Typography>{t('hksas.fillInTheBlanksWriting.questions')}</Typography>
                                <Button
                                    variant='contained'
                                    onClick={() => {
                                        addQuestion();
                                        updateUI(true);
                                    }}
                                    startIcon={<AddIcon />}
                                    disabled={itemContent.questions.length >= questionLimit}
                                    sx={{
                                        height: '36px',
                                        background: '#2196F3',
                                        '&:hover': {
                                            backgroundColor: '#2196F3B3', // replace with your desired color
                                            color: '#000000', // replace with your desired color
                                        },
                                    }}>
                                    {t('hksas.fillInTheBlanksWriting.question', { num: '' })}
                                </Button>
                            </Stack>
                            <Typography color='gray' fontSize={12}>
                                以 [ ] 分辨答案，如：天空[乌]云密布，好像快要下雨了。
                            </Typography>
                            {itemContent.questions &&
                                itemContent.questions.map((question, index) => (
                                    <Stack direction={'row'} spacing={1}>
                                        <TextField
                                            key={index}
                                            label={t('hksas.fillInTheBlanksWriting.question', {
                                                num: index + 1,
                                            })}
                                            type='text'
                                            sx={{ width: '100%' }}
                                            value={question.questionText}
                                            onChange={(e) => {
                                                onQuestionChange(e);
                                                question.questionText = e.target.value;
                                                updateUI(false);
                                                debouncedSaveHandler(() => {
                                                    updateUI(true);
                                                });
                                            }}
                                            // onBlur={(e) => {
                                            //   updateUI(true);
                                            // }}
                                        />
                                        <IconButton
                                            onClick={() => {
                                                handleRemoveQuestion(index);
                                                updateUI(true);
                                            }}
                                            aria-label='remove'
                                            sx={{
                                                width: 20,
                                                height: 20,
                                                color: 'white', // sets the color of the icon
                                                backgroundColor: 'red', // sets the background color of the button
                                                '&:hover': {
                                                    // sets the background color on hover
                                                    backgroundColor: 'darkred',
                                                },
                                                alignSelf: 'center',
                                            }}>
                                            <RemoveIcon />
                                        </IconButton>
                                    </Stack>
                                ))}
                        </Stack>
                    </Box>
                </Box>
            </Stack>
        </>
    );
};
